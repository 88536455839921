'use client';

import { useState } from 'react';
import { LoaderFunction, MetaFunction } from '@remix-run/cloudflare';

import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '~/components/ui/card';
import { Organization } from '~/types';
import { Form, Link, useLoaderData } from '@remix-run/react';
import { organizationsTable } from '../db/schema';
import { eq } from 'drizzle-orm';
import { createOrg404Response } from '../utils/errorResponses';

interface LoaderData {
  organization: Organization;
}

export const loader: LoaderFunction = async ({ request, params, context }) => {
  const { orgSlug } = params;

  if (!orgSlug) {
    throw new Error('orgSlug is required');
  }

  const session = await context.sessionStorage.getSession(
    request.headers.get('Cookie'),
  );

  const organization = await context.drizzle.query.organizationsTable.findFirst(
    {
      where: eq(organizationsTable.slug, orgSlug),
    },
  );

  if (!organization) {
    throw createOrg404Response({ orgSlug });
  }

  session.set('orgSlug', orgSlug);

  return Response.json({ organization } satisfies LoaderData, {
    headers: {
      'Set-Cookie': await context.sessionStorage.commitSession(session),
    },
  });
};

export const meta: MetaFunction = ({ data }) => {
  const loaderData = data as LoaderData;

  return [
    { title: loaderData.organization.name },
    {
      name: 'description',
      content: loaderData.organization.description,
    },
  ];
};

export default function Login() {
  const { organization } = useLoaderData<LoaderData>();

  const [email, setEmail] = useState('');
  const emailIsValid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

  return (
    <Form method="post" action="/magic">
      <div className="flex w-full items-center justify-center p-4">
        <Card className="min-h-[300px] w-full max-w-2xl">
          <CardHeader>
            <CardTitle className="text-5xl">{organization.name}</CardTitle>
          </CardHeader>
          <CardContent className="text-muted-foreground">
            {organization.description}
          </CardContent>
          <CardFooter>
            <div className="flex w-full flex-col gap-4">
              <p>To get started, enter your email below:</p>
              <div className="flex w-full flex-col gap-2 md:flex-row">
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                  }}
                />
                {emailIsValid ? (
                  <Button>Start registration</Button>
                ) : (
                  <Button disabled>Start registration</Button>
                )}
              </div>
              <div className="flex justify-end text-xs text-muted-foreground">
                By proceeding, you accept the&nbsp;
                <Link to="/terms-of-service" className="underline">
                  Terms
                </Link>
                &nbsp;and&nbsp;
                <Link to="/privacy-policy" className="underline">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    </Form>
  );
}
